/* styles/mixins.scss */
@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @mixin responsive-layout($breakpoint) {
    @media (max-width: $breakpoint) {
      @content;
    }
  }
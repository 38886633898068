/* globals.css */

/* Tailwind Base, Components, and Utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS Variables for Dynamic Theming */
:root {
  --header-height: 60px;
  --footer-height: 80px;
  --primary-color: #4a90e2;
  --secondary-color: #50e3c2;
  --background-color-light: #ffffff;
  --background-color-dark: #1a202c;
  --text-color-light: #333333;
  --text-color-dark: #e2e8f0;
  --font-size-base: 16px; /* Default base font size */
  --font-size-mobile: 14px; /* Smaller mobile font size */
  --font-size-large: 18px; /* Large font for wide screens */
  --container-max-width: 1200px;

  /* Modern Fluid Typography */
  font-size: clamp(14px, 1.6vw, var(--font-size-base));
}

/* Adjust Header and Footer Heights for Larger Screens */
@media (min-width: 768px) {
  :root {
    --header-height: 80px;
    --footer-height: 100px;
  }
}

/* Adjust Font Size for Smaller Screens */
@media (max-width: 640px) {
  :root {
    font-size: var(--font-size-mobile);
  }
}

/* Adjust Font Size for Larger Screens */
@media (min-width: 1440px) {
  :root {
    font-size: var(--font-size-large);
  }
}

/* Global Styles */
body {
  @apply antialiased transition-colors duration-300 ease-in-out;
  font-family: 'Inter', sans-serif; /* Modern sans-serif font */
  line-height: 1.75;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* Ensure Text Color Visibility in Dark Mode */
h1, h2, h3, h4, h5, h6, p, a, span, li {
  color: var(--text-color-light);
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4, body.dark-mode h5,
body.dark-mode h6, body.dark-mode p, body.dark-mode a, body.dark-mode span, body.dark-mode li {
  color: var(--text-color-dark);
}

/* Container with Responsive Padding */
.container {
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 1.5rem;
  @apply sm:px-4 md:px-6 lg:px-8;
}

/* Navbar Styles */
.navbar {
  @apply flex items-center justify-between px-4 py-3;
  height: var(--header-height);
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .navbar {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* Footer Styles */
.footer {
  @apply flex items-center justify-center px-4 py-3;
  height: var(--footer-height);
  background-color: var(--background-color-light);
  color: var(--text-color-light);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark-mode .footer {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

/* Button Styles */
.btn {
  @apply inline-flex items-center justify-center px-6 py-3 rounded-lg font-semibold shadow-md transition ease-in-out duration-300;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
}

.btn-primary {
  @apply bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700;
}

.btn-secondary {
  @apply bg-gray-200 text-gray-800 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600;
}

/* Responsive Typography */
h1 {
  font-size: 2.25rem; /* Default for desktop */
}

h2 {
  font-size: 1.875rem;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 640px) {
  h1 {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.875rem;
  }
}
/* styles/layout.scss */
@use 'variables';
@use 'mixins';

body {
  font-family: 'Roboto', sans-serif;
  color: variables.$text-color;
  background-color: variables.$white;
  margin: 0;
  padding: 0;
}

.container {
  max-width: variables.$max-width;
  margin: 0 auto;
  padding: variables.$spacing-unit;
}

.header {
  background-color: variables.$primary-color;
  color: variables.$white;
  padding: variables.$spacing-unit;
  text-align: center;

  h1 {
    margin: 0;
    font-size: 2rem;
  }
}

.main-layout {
  display: grid;
  grid-template-columns: 1fr 250px; /* Main content and sidebar */
  gap: variables.$spacing-unit;
  margin-top: variables.$spacing-unit;

  .content {
    background: variables.$white;
    padding: variables.$spacing-unit;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .sidebar {
    background: variables.$secondary-color;
    color: variables.$white;
    padding: variables.$spacing-unit;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  /* Responsive: Stack layout on smaller screens */
  @include mixins.responsive-layout(768px) {
    grid-template-columns: 1fr;
  }
}

.footer {
  background-color: variables.$primary-color;
  color: variables.$white;
  padding: variables.$spacing-unit;
  text-align: center;
  margin-top: variables.$spacing-unit;
}
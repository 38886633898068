/* src/styles/globals.scss */

/* Global Reset and Box Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: var(--background-color-light); /* Light background */
  color: var(--text-color-light); /* Default text color */
}

body.dark-mode {
  background-color: var(--background-color-dark); /* Dark mode background */
  color: var(--text-color-dark); /* Dark mode text color */
}

/* Turnstile Container */
.turnstile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  width: 100%;
  max-width: 320px;
  position: relative;
}

/* Turnstile Widget */
#turnstile-widget {
  width: 100%;
  max-width: 320px;
  min-height: 80px;
  margin: 0 auto;
}

/* Turnstile Success Feedback */
.turnstile-feedback {
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  background: #d4edda; /* Light green background */
  border: 2px solid #28a745; /* Green border */
  color: #28a745; /* Green text color */
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Show Feedback When Success */
.turnstile-container.success .turnstile-feedback {
  display: flex;
}

/* Add Green Checkmark */
.turnstile-feedback::before {
  content: '✔';
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

/* Responsive Design for Turnstile */
@media (max-width: 768px) {
  #turnstile-widget {
    max-width: 280px;
  }
}

@media (max-width: 480px) {
  #turnstile-widget {
    max-width: 220px;
  }
}

/* Advanced Responsive Typography */
h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}

@media (max-width: 640px) {
  h1 {
    font-size: 1.75rem;
  }

  p {
    font-size: 0.875rem;
  }
}

/* Enhanced Button Styles */
button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: var(--primary-color);
  color: #fff;
}